import React from "react"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { UserConnect } from "@bw/partials"
import { Paragraph } from "grommet"
import parse from "html-react-parser"
import { navigate, graphql } from "gatsby"
import { Formik, Form, Field } from "formik"
import { FormField, Input, TextArea, FileInput } from "@bw/forms"
import { createProfile, getAccessToken } from "@actions/user"
import styled from "styled-components"
import { Layout } from "@bw/layouts"
import { Section, Checkbox, Button, Spacer, Avatar } from "@bw/bits"
import { breakpoints } from "../../theme"
import { toast } from "react-toastify"
import { useUser } from "@contexts/user"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import YupPassword from "yup-password"
YupPassword(yup)

const StyledLink = styled(Link)`
  font-weight: bold;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
`

const GridContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    max-width: 680px;
  }
`

const userSchema = yup.object({
  name: yup
    .string()
    .required()
    .matches(
      new RegExp("^[a-zA-Z0-9_]+$"),
      "Only letters, numbers and underscores are allowed."
    )
    .min(3)
    .max(128),
  email: yup.string().required().email(),
  password: yup
    .string()
    .password()
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .minSymbols(1)
    .min(12)
    .required(),
  repeatpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  bio: yup.string().max(1024),
  avatar: yup.mixed().nullable(),
  website: yup.string().url(),
  privacy_notice_accepted: yup.bool().oneOf([true], "Field must be checked"),
  server_storage_accepted: yup.bool().oneOf([true], "Field must be checked"),
})

function Register({ data, pageContext }) {
  const { t } = useTranslation()
  const [user, dispatch] = useUser()
  const [preview, setPreview] = React.useState(null)
  const { localizedPath, locale, defaultLang } = useLocalization()
  const transparentPixel =
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

  React.useEffect(() => {
    if (user.loggedIn) {
      navigate(
        localizedPath({
          defaultLang,
          locale,
          path: `/user/${user.publicKey}/`,
        })
      )
    } else if (user.token && !user.publicKey) {
      navigate(
        localizedPath({
          defaultLang,
          locale,
          path: `/`,
        })
      )
    }
  }, [user, defaultLang, locale, localizedPath])

  if (typeof window === "undefined") {
    // FileReader cant' be server side rendered
    return null
  }

  const reader = new FileReader()

  return (
    <Layout {...{ pageContext }}>
      <Section
        title={t("user.form.register.title")}
        subtitle={t("user.form.register.subtitle")}
      />
      <Section>
        {!user.publicKey ? (
          <GridContainer>
            <div>
              <p>
                <UserConnect />
              </p>
              <Paragraph size="small">
                {t("user.form.register.loginSentence")}{" "}
                <StyledLink to="/user/login/">
                  {t("user.form.register.login")}
                </StyledLink>
              </Paragraph>
            </div>
            <div>
              <p>{t("user.form.register.walletSentence")}</p>
              <p>
                {parse(
                  t("user.form.register.needHelp", { url: t("links:faq.path") })
                )}
              </p>
            </div>
          </GridContainer>
        ) : (
          <Formik
            initialValues={{
              avatar: null,
              name: "",
              email: "",
              password: "",
              repeatpassword: "",
              bio: "",
              website: "",
              privacy_notice_accepted: false,
              server_storage_accepted: false,
            }}
            validationSchema={userSchema}
            onSubmit={(values, { setSubmitting }) => {
              createProfile(values)
                .then(response => {
                  if (response.status !== 201) {
                    throw new Error("Account creation failed")
                  }

                  // directly log the user in
                  return getAccessToken(values.email, values.password, dispatch)
                })
                .then(() => {
                  navigate(
                    localizedPath({
                      defaultLang,
                      locale,
                      path: `/user/${user.publicKey}/`,
                    })
                  )
                })
                .catch(err => {
                  // these are server validation errors
                  err?.response?.data?.errors &&
                    Object.values(err.response.data.errors).forEach(error => {
                      toast.error(error.join("\n"))
                    })
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form data-cy="register-form">
                <GridContainer>
                  <div>
                    <FormField name="avatar">
                      <Spacer direction="row">
                        <Avatar
                          size="large"
                          image={
                            preview || user.data.avatar_url || transparentPixel
                          }
                          alt="profile picture."
                        />
                        <div>
                          <FileInput
                            name="avatar"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onClear={() => {
                              setFieldValue("avatar", null)
                              setPreview(null)
                            }}
                            onChange={e => {
                              setFieldValue("avatar", e.currentTarget.files[0])
                              reader.onload = () => {
                                setPreview(reader.result)
                              }
                              reader.readAsDataURL(e.currentTarget.files[0])
                            }}
                          />
                        </div>
                      </Spacer>
                    </FormField>
                    <FormField
                      title={t("user.form.name")}
                      icon="Public"
                      help={t("user.form.helpName")}
                      name="name"
                    >
                      <Field name="name" component={Input} />
                    </FormField>
                    <FormField
                      name="email"
                      title={t("user.form.email")}
                      icon="Private"
                    >
                      <Field name="email" type="email" component={Input} />
                    </FormField>
                    <FormField
                      name="website"
                      title={t("user.form.website")}
                      icon="Public"
                    >
                      <Field name="website" component={Input} />
                    </FormField>
                  </div>
                  <div>
                    <FormField
                      name="bio"
                      title={t("user.form.bio")}
                      icon="Public"
                    >
                      <Field name="bio" component={TextArea} />
                    </FormField>
                    <FormField
                      title={t("user.form.password")}
                      icon="Private"
                      name="password"
                      help={t("user.form.helpPassword")}
                    >
                      <Field
                        name="password"
                        type="password"
                        component={Input}
                      />
                    </FormField>
                    <FormField
                      title={t("user.form.repeatpassword")}
                      name="repeatpassword"
                      icon="Private"
                    >
                      <Field
                        name="repeatpassword"
                        type="password"
                        component={Input}
                      />
                    </FormField>

                    <FormField name="privacy_notice_accepted">
                      <Field
                        name="privacy_notice_accepted"
                        type="checkbox"
                        label={parse(
                          t("user.form.register.acceptPrivacyNotice", {
                            url: data.globals.private_policy_page.url.replace(
                              "/en/",
                              "/"
                            ),
                          })
                        )}
                        component={Checkbox}
                      />
                    </FormField>
                    <FormField name="server_storage_accepted">
                      <Field
                        name="server_storage_accepted"
                        type="checkbox"
                        label={parse(
                          t("user.form.register.acceptServerStorage", {
                            url: data.globals.private_policy_page.url.replace(
                              "/en/",
                              "/"
                            ),
                          })
                        )}
                        component={Checkbox}
                      />
                    </FormField>
                    <p>
                      <Button
                        data-cy="create-profile"
                        submit
                        label={t("user.form.register.buttonCreateAccount")}
                        disabled={isSubmitting}
                      />
                    </p>
                    <Paragraph size="small">
                      {t("user.form.register.confirmation")}
                    </Paragraph>
                    <Paragraph size="small">
                      {t("user.form.register.loginSentence")}{" "}
                      <StyledLink to="/user/login/">
                        {t("user.form.register.login")}
                      </StyledLink>
                    </Paragraph>
                  </div>
                </GridContainer>
              </Form>
            )}
          </Formik>
        )}
      </Section>
    </Layout>
  )
}

export default Register

export const registerQuery = graphql`
  query registerQuery($locale: String) {
    globals(
      private_policy_page: { url: { ne: null } }
      locale: { eq: $locale }
    ) {
      private_policy_page {
        url
      }
    }
  }
`
